<template>
    <div id="v-pills-assessments" role="tabpanel" aria-labelledby="v-pills-assessments-tab">
       <div class="col content">
          <h5><strong>Employers who viewed your profile</strong></h5>
          <div class="row mt-5" v-if="viewers.length">
            <div class="col-md-12 pb-0" v-for="(viewer,index) in viewers" :key="index">
              <div>{{viewer}}</div>
            </div>  
          </div>
       </div>
    </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '../assets/hire.css';
export default {
  name: 'ProfileViewers',
  data(){
    return{
      action: new Actions(),
      viewers: []
    }
  },
  beforeMount(){
    this.loadViewers();
  },
  methods:{
    loadViewers() {
      let token = this.$cookies.get("hire-seeker").token;
      this.action.getProfileViews(token).then((res) => {
          if(res.status) {
              this.viewers = res.views;
              console.log(this.viewers);
          }
      }).catch((err) => {
          console.log(err)
      });
    }
  }
}
</script>
<style>
  .btn.expired {
    font-size: 0.75rem;
    color: white;
    background-color: #7cafcf ;
    text-transform: capitalize;
    /* cursor: not-allowed !important; */
  }

  .btn.active, .btn.upcoming {
      color: white;
      font-size: 0.75rem;
      background-color: green;
      text-transform: capitalize;
  }
  .job_desgination {
    color:#0054ad;
    font-weight: bold;
  }
  .expired, .expired p{
    color: #8f8f8f;
  }
</style>
